<template>
  <div class="nanfanNewsBox">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>今日南繁</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <el-row>
        <el-col :span="12">
          <div class="newsBlock_outside">
            <div class="newsBlock_inside">
              <el-carousel trigger="click" height="400px">
                <el-carousel-item v-for="(item,index) in hainanNewsList" :key="index">
                  <img class="rollPic" :src="item.url" alt />
                  <div class="rollFont">
                    <p>{{ item.title }}</p>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="newsBlock_outside">
            <div class="newsBlock_inside">
              <div class="nbi_title">
                <div class="nbit_font nbit_click" @click="tonewsList(1)">海南要闻</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in hainanNewsList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(0,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="newsBlock_outside">
            <div class="newsBlock_inside">
              <div class="nbi_title">
                <div class="nbit_font nbit_click" @click="tonewsList(2)">最新政策</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in policyList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(1,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="newsBlock_outside">
            <div class="newsBlock_inside">
              <div class="nbi_title">
                <div class="nbit_font nbit_click" @click="tonewsList(3)">通知公告</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in noticeList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(2,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/nanfanNews/nanfanNews.css";
import Vue from "vue";
import { Carousel, CarouselItem } from "element-ui";

Vue.use(Carousel).use(CarouselItem);

export default {
  data() {
    return {
      columnIds: {
        news: "d1707e23348143fb99537c9bddffcdb3", // 最新要闻
        newsX: "3373f13e83b74f12a213a53db20a6657", // 最新政策
        notice: "abb9308a63f64190b55633b83cc27e12" // 通知公告
      },
      hainanNewsList: [],
      policyList: [],
      noticeList: []
    };
  },
  async created() {
    await this.getData("news");
    await this.getData("newsX");
    await this.getData("notice");
  },
  methods: {
    async getData(opt) {
      const api = this.$fetchApi.dynamicNewsList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: 1,
          pageSize: 6,
          columnId: this.columnIds[opt]
        },
        api,
        "json"
      );
      if (data.code === "200") {
        let nowData = data.result.rows;
        if (opt == "news") {
          for (let i in nowData) {
            this.hainanNewsList.push({
              url: nowData[i].mainImgUrl,
              title: nowData[i].title,
              time: nowData[i].releaseDate,
              id: nowData[i].informationId
            });
          }
        }
        if (opt == "newsX") {
          for (let i in nowData) {
            this.policyList.push({
              url: nowData[i].mainImgUrl,
              title: nowData[i].title,
              time: nowData[i].releaseDate,
              id: nowData[i].informationId
            });
          }
        }
        if (opt == "notice") {
          for (let i in nowData) {
            this.noticeList.push({
              url: nowData[i].mainImgUrl,
              title: nowData[i].title,
              time: nowData[i].releaseDate,
              id: nowData[i].informationId
            });
          }
        }
      }
    },
    toDetail(idx, id) {
      this.$router.push({
        path: "/home/news/detail",
        query: {
          idx: idx,
          id: id,
          prev: "nanfanNews"
        }
      });
    },
    tonewsList(num) {
      this.$router.push({
        path: "/newsList",
        query: {
          num: num
        }
      });
    }
  }
};
</script>
<style scoped>
</style>